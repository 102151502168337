// extracted by mini-css-extract-plugin
export var tablet_min = "768px";
export var desktop_min_sm = "992px";
export var desktop_min = "1100px";
export var desktop_min_lg = "1220px";
export var popup_breakpoint = "1399px";
export var titleContainer = "bm_dC";
export var tableContainer = "bm_dN";
export var checkmark = "bm_dG";
export var xmark = "bm_dH";
export var tableTitles = "bm_dP";
export var tableContent = "bm_dD";