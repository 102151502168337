// extracted by mini-css-extract-plugin
export var tablet_min = "768px";
export var desktop_min_sm = "992px";
export var desktop_min = "1100px";
export var desktop_min_lg = "1220px";
export var popup_breakpoint = "1399px";
export var indicator = "bj_dt";
export var steps = "bj_dv";
export var step = "bj_dw";
export var icon = "bj_dx";
export var present = "bj_dy";
export var finish = "bj_dz";
export var line = "bj_dB";