// extracted by mini-css-extract-plugin
export var tablet_min = "768px";
export var desktop_min_sm = "992px";
export var desktop_min = "1100px";
export var desktop_min_lg = "1220px";
export var popup_breakpoint = "1399px";
export var modalAlertContainer = "bh_dm";
export var modalAlertContent = "bh_dn";
export var animatetop = "bh_dp";
export var modalAlertHeader = "bh_dq";
export var modalAlertBody = "bh_dr";
export var modalAlertFooter = "bh_ds";