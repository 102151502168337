// extracted by mini-css-extract-plugin
export var tablet_min = "768px";
export var desktop_min_sm = "992px";
export var desktop_min = "1100px";
export var desktop_min_lg = "1220px";
export var popup_breakpoint = "1399px";
export var titleContainer = "bn_dC";
export var tableContent = "bn_dD";
export var disclosureDiv = "bn_dF";
export var checkmark = "bn_dG";
export var xmark = "bn_dH";
export var negative = "bn_dJ";